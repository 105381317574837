<template>  
	<div>
		
		<v-app-bar
			color="primary"
			dark
			fixed
			class="hidden-md-and-down z-99"
			height="65"
		> 
			<v-container grid-list-xl>
				<v-layout row wrap align-center justify-space-between pa-0 ma-0>
					<v-flex xs6 sm6 md4 lg2 xl2 px-0>
						<div class="site-logo d-inline-block">
							<router-link to="/">
								<img alt="site-logo" width="140" :src="(logo) ? logo : appLogo" />
							</router-link>
						</div>
					</v-flex>
					<v-flex xs12 sm12 md5 lg7 xl6 px-0>
						<emb-menu></emb-menu>
					</v-flex>
					<v-flex xs6 sm6 md3 lg3 xl3 px-0>
						<div class="notifications d-flex align-items-center justify-end">
							<emb-cart></emb-cart>
							<!-- <emb-wishlist></emb-wishlist> -->
							<emb-user-block></emb-user-block>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</v-app-bar>
		<v-app-bar fixed class="hidden-lg-and-up z-99 primary" height="65">
            <v-container grid-list-xl>
                <div class="bottom-header-inner">
                    <div class="layout align-center d-flex justify-space-between responsive-menu">
                        <div class="site-logo d-inline-block">
                            <router-link to="/">
                                <img alt="site-logo" height="34" :src="logo ? logo : appLogo" width="98" />
                            </router-link>
                        </div>
						<emb-cart></emb-cart>
                        <emb-user-block></emb-user-block>
                        <v-btn icon @click="toggleMobileSidebar" large>
                            <v-icon class="white--text">mdi-menu</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-container>
        </v-app-bar>

		<!-- <div class="bottom-header d-block primary">
			<v-container>
				<div class="bottom-header-inner">
					<div class="layout align-left responsive-menu">
						<v-btn icon dark @click="toggleMobileSidebar" class="toggle-btn" large>
							<i class="material-icons">menu</i>
						</v-btn>
						<v-spacer></v-spacer>
						<emb-cart class="pt-1"></emb-cart>
						<emb-user-block></emb-user-block>
					</div>
				</div>
			</v-container>
		</div> -->
		<emb-location-dialog
            :showLocationDialog.sync="showLocationDialog"
            :isRouteMenu="false"
            :lat="lat"
            :lng="lng"
			:draggable="true"
			:autoAdd="false"
			:item="item"
        >
        </emb-location-dialog>
	</div>
</template>

<script>
	import Menu from './Menu';
	import Cart from './Cart'
	// import Wishlist from './Wishlist'
	import UserBlock from './UserBlock'
	import AppConfig from "Constants/AppConfig";

	export default {
		props: ['logo'],
		data () {
			return {
				appLogo: AppConfig.appLogo,
				showLocationDialog: false,
				item: null,
				lat: null,
				lng: null,
			}
		},
		components: {
			embMenu: Menu,
			embCart: Cart,
			// embWishlist: Wishlist,
			embUserBlock:UserBlock
		},
		mounted() {
			// this.toggleMobileSidebar()
		},
		created() {
            this.$eventHub.$on('openLocationDialog', () => {
                this.showLocationDialog = true
            })
        },
		methods: {
			toggleMobileSidebar() {
				this.$store.dispatch("toggleSidebar", true);
			},
			getCompanyData() {
				this.$http.get(`${this.resource}/record`).then(response => {
                    this.contact = response.data.data
                })
			}
		}	
	};
</script>

